.details-header {
  a {
    color: #72747b;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.mlinkImage{
  max-height: 12px;
}

.mLinkA{
  display: flex;
  align-items: center;
  justify-content: center;
}

.noImage, .previewImage{
  max-width: 100%;
  width: 220px;
  border: 1px solid #b2b2b2;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-left: auto;

  p{
    max-width: 130px;
  }
}

.previewImage{

  width: 100%;

  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .expand-image{
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0, .5);

    img{
      width: 50px;
      object-fit: contain;
    }
  }

  &:hover .expand-image{
    display: block;
  }
}

.product_details_header{
  justify-content: space-between;
  display: flex;
  align-items: flex-start;

  .col-md-3{
    min-height: 26px;
  }
}

.header_cid_dropdown{
  max-width: 100%;
  width: 210px;
  border: 1px solid rgba(152, 166, 173, 0.2);
  border-radius: 4px;
  height: 26px;
  overflow: hidden;
  position: relative;


  .toggle_dropdown{
    cursor: pointer;
    position: absolute;
    top: 3px;
    right: 0;
    width: 26px;
    height: 20px;
    display: flex;
    align-items: center;
    border-left: 1px solid rgba(152, 166, 173, 0.2);
    justify-content: center;

    i{
      font-size: 16px;
      transition: all .6s;
    }
  }

  
  &.open_dropdown{
    height: auto;
    position: absolute;
    z-index: 9;
    background: #fff;

    .toggle_dropdown > i{
      transform: rotate(180deg);
      transition: all .6s;
    }
  }

  .cid_item{
    width: 100%;
    display: flex;
    align-items: center;
    height: 24px;
    padding: 0 5px;

    &:hover{
      background-color: #ededed;
    }

    p{
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }

    .mLinkA{
      margin-left: auto;
      margin-right: 25px;
    }

    img{
      width: 14px;
      object-fit: contain;
      margin-right: 5px;
    }
  }
}



.header__dropdown__select{
  height: 26px !important;
  padding-left: 8px !important;
  

  .custom__select__dropdown{
    width: 100% !important;
    left: 0 !important;
    padding: 8px 12px;
  }

  .custom__select__title{
    font-size: 10px;
  }
}