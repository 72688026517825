#backupList thead th, #backupList tbody tr td{
    text-align: center;
    display: block;
    min-width: 160px;
    width: unset;
    border: unset;
}
#backupList thead{
    border-top: 1px solid #dee2e6;
    border-bottom: 2px solid #dee2e6;
}

#backupList thead th:first-child, #backupList tbody tr td:first-child{
    display: none;
}
#backupList  tr{
    display: flex;
}

#backupList thead th:last-child, #backupList tbody tr td:last-child{
    text-align: left;
    min-width: 550px !important;
}
  
#backupList tbody tr td:nth-child(4),#backupList thead  th:nth-child(4),
    #backupList tbody tr td:nth-child(5), #backupList thead  th:nth-child(5){
    min-width: 130px !important;
}

.th-sm{
    width: 60px !important;
}
