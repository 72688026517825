#content-settings input[type='number']{
    width: 55px;
    padding: 0;
    margin-bottom: 5px;
    text-align: center;
}

#content-settings input::-webkit-outer-spin-button,
#content-settings input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

#content-settings .table-responsive{
    overflow-y: hidden;
}

#content-settings ul{
    padding-left: 5px;
}

#content-settings ul > li:not(:last-child){
    margin-bottom: 20px;
}

#content-settings thead tr th:first-child{
    min-width: 140px;
}

#content-settings thead tr th:nth-child(2){
    min-width: 350px;
}

#content-settings thead tr th:nth-child(3){
    min-width: 80px;
    text-align: center;
}

#content-settings thead tr th:nth-child(4){
    width: 100%;
    padding-left: 25px;
}