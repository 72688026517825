
.scroll-x{
    overflow-x: scroll;
    flex-wrap: nowrap;
    padding-bottom: 10px;
    position: relative;
  
      /* width */
    &::-webkit-scrollbar {
      height: 4px;
    }
  
    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
  
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: transparent;
    }

    &:hover::-webkit-scrollbar-thumb{
        background-color: #888;
    }

  
    & > li{
      flex: 0 0 fit-content;
      margin-bottom: 0 !important;
    }
}

.scroll-x:hover + .relative{
    display: none;
}

.relative{
    position: relative;
    width: 100%;
}

.slide_indicator{
    position: absolute;
    right: -25px;
    bottom: -28px;
    animation: float 2s ease-in-out infinite;

    img{
        max-width: 20px;
    }
}
  
@keyframes float {
	0% {
		transform: translatex(0px);
	}
	50% {
		transform: translatex(-10px);
	}
	100% {
		transform: translatex(0px);
	}
}