.btn-component button {
    background-color:#19315A;
    transition:200ms;
    border:0px;
  
} 



.btn-component button:hover {
    background-color:#2B549F;
}

.btn-primary {
    background-color:#19315A !important;
    border:0px;
}
.btn-primary:hover {
    background-color:#2B549F !important;
}

.btn-success {
    background-color:#118844 !important;
    border:0px;
}
     
.btn-warning {
    background-color:#F9BA72 !important;
    border:0px;
}

.btn-danger {
    background-color:#CC4422 !important;
    border:0px;
}
.custom-btn {
    width:100px;
    font-size:12px; 
}