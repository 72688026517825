.actions-cell {
  position: relative;
  overflow: visible;

  i {
    cursor: pointer;
  }

  .actions-dropdown {
    position: absolute;
    top: 15px;
    right: 40px;
    background: #ffffff;
    text-align: left;
    padding: 0.2rem;
    z-index: 999;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.4);
    border-radius: 3px;

    li {
      list-style-type: none;
      padding: 0.1rem 0.3rem;
      cursor: pointer;

      &:hover {
        background: #f7f7f7;
      }
    }
  }
}
