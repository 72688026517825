.fallback {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;

  .fallback__wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
    width: 350px;
    height: 350px;

    .fallback__logo-wrapper {
      text-align: center;
      .fallback__logo {
        height: 120px;
        object-fit: cover;
      }
    }

    p {
      margin: 0;
      text-align: center;
    }

    .fallback__primary-text {
      font-weight: 700;
      font-size: 1.2rem;
      line-height: 1.5rem;
      color: #000000;
    }

    .fallback__secondary-text {
      margin: 2rem 0;
    }
  }
}
