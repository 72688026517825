footer.footer {
  a {
    color: #98a6ad;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
