.unitEconomics td{
    overflow: unset;
}

.u_infoButton{
    margin-left: 5px;
    position: relative;
    cursor: pointer;

    img{
        width: 16px;
    }
}

.vm{
    background-color: #e7e7e7 !important;
}

.vm td{
    vertical-align: middle;
}


.u_infoButton > .infoBox{
    position: absolute;
    bottom: 29px;
    left: -20px;
    width: 180px;
    height: auto;
    background-color: #566676;
    border-radius: 3px;
    padding: 10px;
    color: #fff;
    font-weight: lighter;
    font-size: 12px;
    display: none;
    z-index: 99;
}

.u_infoButton:hover .infoBox{
    display: block;
}

.infoBox > span{
    white-space: break-spaces;
    display: block;
}
 
.u_infoButton:hover::after{
    content: '';
    position: absolute;
    top: -10px;
    left: 0;
    width: 0; 
    height: 0; 
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #566676;
}

.upsd_infoButton:hover::after{
    transform: rotate(180deg);
    top: 26px;
}

.upsd_infoButton > .infoBox{
    bottom: -160px;
}

.tooltipDown .u_infoButton > .infoBox{
    bottom: -90px;
}

.tooltipDown .u_infoButton:hover::after{
    transform: rotate(180deg);
    top: 28px;
}

.unit_ec_error .fallback {
    align-items: self-start;
    margin-top: 100px;
}

.unit_ec_error .fallback .fallback__wrapper{
    width: 500px;
    min-height: 350px;
    height: auto;
}

.unit_ec_error .text-error-normal{
    font-size: 16px;
}