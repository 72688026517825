.logo {
  height: 100%;
  line-height: initial;
}

.logo > span {
  height: 100%;
}

.logo > span > img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.button-menu-mobile {
  cursor: pointer;
}

.logo-box {
  min-width: 200px;
}

.left-side-menu {
  position: fixed !important;
}

.refreshReports{
  width: 25px;
  height: 25px;
  border-radius: 3px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: rgba(114, 116, 123, .4);
}

.refreshReports img{
  width: 12px;
}

.pageName{
  height: 100%;
  display: flex;
  align-items: center;
  border-left: 0.5px solid rgba(255,255,255, .2);
}

.pageName h3{
  color: #fff;
  margin-left: 15px;
}

.search_icon{
  cursor: pointer;
  width: 45px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.search_icon img{
  position: absolute;
  right: 12px;
  top: 8px;
  width: 18px;
  height: 18px;
  object-fit: contain;
}

.search_response{
  position: absolute;
  width: 100%;
  max-height: 250px;
  background: #fff;
  top: 50px;
  padding: 5px 0;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow-y: auto;
  box-shadow: 0px 1px 20px 2px rgba(0,0,0,.3);
}

.search_response a{
  padding: 3px 20px;
  color: #707070;
}

.search_response a:hover{
  background-color: #e0ebfd;
  color: #003566;
}


.search_response::-webkit-scrollbar {
  width: 2px;
}
 
/* .search_response::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 53, 102, .5);
} */
 
.search_response::-webkit-scrollbar-thumb {
  background-color: #003566;
  outline: 1px solid #003566;
}


li.groupHeading + .option {
  color: #908e8e;
  padding: 5px 15px;
  font-size: 14px;
  background-color: #fff !important;
}
li.groupHeading + .option:hover {
  color: #908e8e !important;
}
.groupHeading{
  display: none;
}