.user-tabs {
  display: flex;
  align-items: flex-end;
  margin-bottom: 1.5rem;

  .tab {
    padding: 0.5rem 1.5rem;
    font-weight: 600;
    border-bottom: 2px solid rgba(152, 166, 173, 0.2);
    cursor: pointer;

    &.active {
      border-bottom-color: #19315a;
    }
  }

  .tab-bar {
    width: 100%;
    height: 100%;
    border-bottom: 2px solid rgba(152, 166, 173, 0.2);
  }
}

.pl-50{
  padding-left: 50px !important;
}