.cursor-pointer {
  cursor: pointer;
}

tbody tr:nth-child(odd) {
  background-color: #f7f7f7;
}

.table-filter-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ced4da;
  width: 50px;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
}

.table-filter-button.active {
  color: #19315a;
}


.dropdown__menu{
  position: absolute;
  background: #fff;
  box-shadow: 0 2px 20px 7px rgba(0,0,0, .1);
  bottom: -150px;
  width: max-content;
  display: flex;
  flex-direction: column;
  z-index: 999;
}

.dropdown__menu__item{
  padding: 4px 16px;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: #fff;
}

button.dropdown__menu__item:disabled p{
  color: #9d9e9f;
}

.dropdown__menu__item:hover{
  background-color: lightgray;
}

.dropdown__menu__item a{
  text-decoration: none;
  color: #343a40;
}

.dropdown__menu__item p{
  margin-bottom: 0;
  color: #343a40;
}