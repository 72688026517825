.react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text{
    display: block;
    width: 23%;
    height: 42px;
    line-height: 42px;
    float: left;
    margin: 1%;
    cursor: pointer;
    border-radius: 4px;
    color: #6c757d;
}

.react-datepicker__month-container{
    width: 248px;
    padding: 10px 0;
}

.react-datepicker__header{
    background-color: transparent;
    border: none;
}

.react-datepicker__navigation{
    top: 22px;
}

.react-datepicker__navigation--previous{
    left: 20px;
}

.react-datepicker__navigation--next{
    right: 20px;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected{
    background-color: #19315a;
    color: #fff !important;

    &:hover{
        background-color: #2B549F;
    }
}


.calendarInput{
    position: relative;
}

.calendarIcon{
    position: absolute;
    width: 20px;
    right: 12px;
    top: 8px;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
    color: #6c757d;
}