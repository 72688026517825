.import-loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;

  .spinner {
    width: 60px;
    height: 60px;
  }

  p {
    margin-top: 2rem;
    font-size: 0.9rem;
  }
}
