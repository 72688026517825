.page-link:hover {
    color:white;
    background-color:#19315A;
    transition:200ms;
}

.page-item.active .page-link {
    background-color:#19315A;
}

@media only screen and (max-width: 600px) {
    .page-item:first-child{
        padding-top:0px;
    }
    
    .page-item:last-child{
        padding-top:0px;
    }
    .page-item {
        padding-top:8px;
    }

    .dataTables_info {
        text-align:center;
        margin-bottom:10px;
    }

}
