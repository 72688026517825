.fulfilment-costs th, .fulfilment-costs td, .warehouse-costs th, .warehouse-costs td{
    text-align: left !important;
}

// .fulfilment-costs th,.fulfilment-costs td{
//     max-width: 140px;
// }

.modal{
    overflow-y: auto;
}

.infoButton{
    margin-left: 5px;
    position: relative;
    cursor: pointer;

    img{
        width: 16px;
    }
}

.infoButton:hover::before{
    content: 'Always enter max. values per period';
    position: absolute;
    top: -70px;
    left: -10px;
    width: 200px;
    height: 60px;
    background-color: #566676;
    border-radius: 3px;
    padding: 4px;
    color: #fff;
    font-weight: lighter;
    font-size: 12px;
}
.infoButton:hover::after{
    content: '';
    position: absolute;
    top: -10px;
    left: 0;
    width: 0; 
    height: 0; 
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #566676;
}

.dividerDiv{
    display: flex;

    p{
        font-weight: bolder;
        color: #000;
        margin-bottom: 0;
    }
}

.currencyIcon{
    position: absolute;
    top: 9px;
    right: 10px;
}

td{
    max-width: 220px;
}