.import-job-table {
  overflow-x: auto;

  .import-job-table__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .import-job-table__header--item {
      display: flex;
      align-items: center;

      p {
        margin: 0 0.7rem 0 0;
      }
    }

    
  }

  table {
    tr {
      border-bottom: 1px solid #e9e9e9;

      th {
        text-transform: capitalize;
      }

      td {
        a {
          color: #72747b;
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }
      }

      &.gray {
        background: #f7f7f7;
      }
      &.white {
        background: #ffffff;
      }

      .extended-values {
        padding: 1rem 2rem;
      }
    }
  }
}

.modal-error-field__close-btn {
  margin-left: auto;
  height: 16px;
}