.analytics-tabs {
    .tab:first-child {
        min-width: 152px;
    }
}

.reports-page-table tbody tr td:first-child, 
.reports-page-table thead tr th:first-child {
    display: none;
} 



.sections-page-table thead tr th:nth-child(5) {
    padding-right: 25px;
}

.list-toggle-btn{
    width: 120px;
    margin-left: 20px;
}

#manufcaturersSelect svg{
    height: 16px;
    width: 16px;
}

.reportPage{
    position: relative;
    margin: 0;
}

.reportPage > div{
    padding: 0;
}

.reportPage:before{
    content: '';
    position: absolute;
    top: 35px;
    width: 100%;
    left: 0;
    height: 2px;
    background-color: rgba(152, 166, 173, 0.6);
}



.rfrBtn > img{
    filter: brightness(0) invert(1);
    height: 16px;
    width: 16px;
    margin-left: 10px;
}

.custom-table{
    background: #fff !important;
}

.custom-table table{
    width: 100%;
    overflow-x: auto;
    display: block;
    background: #fff !important;
}

.custom-table tbody tr td, .custom-table thead tr th{
    text-align:  center !important;
    background: #fff !important;
}

.custom-table thead tr th{
    min-width: 250px;
    width: 350px;
}

.draggableSpan{
    list-style: none;
    padding: 6px 5px;
    cursor: pointer;
    user-select: none;
    border: 2px solid rgba(66, 114, 138, 0.2);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: #ccc;
    margin-bottom: 2px;
}

.custom-table .draggableSpan{
    background-color: transparent !important;
}

.draggableSpan:hover{
    background-color: #ccc;
}

.draggableSpan:focus, .draggableSpan:active{
    background-color: #ccc;
}

.draggableSpan > img{
    width: 12px;
    object-fit: contain;
    margin-right: 15px;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
}

.flex-direction-column{
    flex-direction: column;
}

.pbi-svg-item{
    display: none !important;
}

.report{
    height: 100%;

    iframe{
        border: none !important;
    }
}