.modal-error-field {
  display: flex;
  padding: 0.7rem;
  padding-left: 0.75rem;
  border-radius: 5px;
  margin-bottom: 0.25rem;
  background-color: rgba(241, 85, 108, 0.18) !important;
  border-color: rgba(241, 85, 108, 0.18) !important;
  border: 1px solid;
  font-weight: 500;
  color: #343a40;
  font-size: 0.9rem;

  &.modal-thin {
    padding: 0.3rem 0.7rem;
  }

  .modal-error-field__close-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0.5rem;
    font-size: 1.5rem;
    cursor: pointer;
    opacity: 0.5;

    &.form-error-close-button {
      height: 19px;
      margin-left: auto;
    }
  }
}


.warning{
  background-color: #ffeccb !important;
  border-color: #ffa438;

  .modal-error-field__close-btn {
    color:#ffa438;
  }
}