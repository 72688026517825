@import url(https://fonts.googleapis.com/css?family=Lato);
.logo {
  height: 100%;
  line-height: normal;
  line-height: initial;
}

.logo > span {
  height: 100%;
}

.logo > span > img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.button-menu-mobile {
  cursor: pointer;
}

.logo-box {
  min-width: 200px;
}

.left-side-menu {
  position: fixed !important;
}

.refreshReports{
  width: 25px;
  height: 25px;
  border-radius: 3px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: rgba(114, 116, 123, .4);
}

.refreshReports img{
  width: 12px;
}

.pageName{
  height: 100%;
  display: flex;
  align-items: center;
  border-left: 0.5px solid rgba(255,255,255, .2);
}

.pageName h3{
  color: #fff;
  margin-left: 15px;
}

.search_icon{
  cursor: pointer;
  width: 45px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.search_icon img{
  position: absolute;
  right: 12px;
  top: 8px;
  width: 18px;
  height: 18px;
  object-fit: contain;
}

.search_response{
  position: absolute;
  width: 100%;
  max-height: 250px;
  background: #fff;
  top: 50px;
  padding: 5px 0;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow-y: auto;
  box-shadow: 0px 1px 20px 2px rgba(0,0,0,.3);
}

.search_response a{
  padding: 3px 20px;
  color: #707070;
}

.search_response a:hover{
  background-color: #e0ebfd;
  color: #003566;
}


.search_response::-webkit-scrollbar {
  width: 2px;
}
 
/* .search_response::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 53, 102, .5);
} */
 
.search_response::-webkit-scrollbar-thumb {
  background-color: #003566;
  outline: 1px solid #003566;
}


li.groupHeading + .option {
  color: #908e8e;
  padding: 5px 15px;
  font-size: 14px;
  background-color: #fff !important;
}
li.groupHeading + .option:hover {
  color: #908e8e !important;
}
.groupHeading{
  display: none;
}
.modal-form label {
  font-weight: 600;
  margin-bottom: 0.3rem;
}
.modal-error-field {
  display: flex;
  padding: 0.7rem;
  padding-left: 0.75rem;
  border-radius: 5px;
  margin-bottom: 0.25rem;
  background-color: rgba(241, 85, 108, 0.18) !important;
  border-color: rgba(241, 85, 108, 0.18) !important;
  border: 1px solid;
  font-weight: 500;
  color: #343a40;
  font-size: 0.9rem;
}
.modal-error-field.modal-thin {
  padding: 0.3rem 0.7rem;
}
.modal-error-field .modal-error-field__close-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0.5rem;
  font-size: 1.5rem;
  cursor: pointer;
  opacity: 0.5;
}
.modal-error-field .modal-error-field__close-btn.form-error-close-button {
  height: 19px;
  margin-left: auto;
}

.warning {
  background-color: #ffeccb !important;
  border-color: #ffa438;
}
.warning .modal-error-field__close-btn {
  color: #ffa438;
}
footer.footer a {
  color: #98a6ad;
  text-decoration: underline;
}
footer.footer a:hover {
  text-decoration: none;
}
.cursor-pointer {
  cursor: pointer;
}

tbody tr:nth-child(odd) {
  background-color: #f7f7f7;
}

.table-filter-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ced4da;
  width: 50px;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
}

.table-filter-button.active {
  color: #19315a;
}


.dropdown__menu{
  position: absolute;
  background: #fff;
  box-shadow: 0 2px 20px 7px rgba(0,0,0, .1);
  bottom: -150px;
  width: -webkit-max-content;
  width: max-content;
  display: flex;
  flex-direction: column;
  z-index: 999;
}

.dropdown__menu__item{
  padding: 4px 16px;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: #fff;
}

button.dropdown__menu__item:disabled p{
  color: #9d9e9f;
}

.dropdown__menu__item:hover{
  background-color: lightgray;
}

.dropdown__menu__item a{
  text-decoration: none;
  color: #343a40;
}

.dropdown__menu__item p{
  margin-bottom: 0;
  color: #343a40;
}
td {
  max-width: 175px;
  position: relative;
}

td > span, td > p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 18px;
}

.disabled-link {
  pointer-events: none;
}

.td-link {
  color: #00488c;
}

li > img.flag{
  width: 20px;
  margin: 0 15px;
}

li._li{
  list-style-type: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

td > a{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}



li::marker{
  display: none;
  content: unset;
}

.action_buttons{
  position: absolute;
  bottom: -30px;
  right: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  z-index: 999;
}

.action_btn{
  width: 30px;
  height: 30px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 4px;
  margin: 0 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.action_btn:hover{
  background-color: #efefef;
}
.btn_icon {
    background:  #19315A;
    cursor:  pointer ;
    font-size:  10px ; 
    padding:  12px 0px  ; 
    float:  left ; 
    border-radius:  3px ; 
    color:  white ; 
    margin-top:  3px ;
    text-align:center;
}
 .rc-backdrop {
	 position: fixed;
	 top: 0;
	 left: 0;
	 bottom: 0;
	 right: 0;
	 z-index: 80;
}
 .rc-backdrop.invert {
	 background: rgba(1, 1, 1, .7);
}
 .range-picker-input {
	 display: inline-flex;
	 border: 1px solid #e4e4e4;
	 width: 300px;
}
 .range-picker-input * {
	 box-sizing: border-box;
}
 .range-picker-input__icon {
	 display: inline-flex;
	 align-items: center;
}
 .range-picker-input__start, .range-picker-input__end {
	 display: inline-flex;
	 flex: 1 1;
}
 .range-picker-input__start .picker-input.range input, .range-picker-input__end .picker-input.range input {
	 width: 100%;
	 border: none;
}
 .picker-input {
	 display: inline-block;
	 position: relative;
}
 .picker-input__icon {
	 position: absolute;
	 top: 50%;
	 -webkit-transform: translateY(-50%);
	         transform: translateY(-50%);
	 left: 10px;
	 display: inline-flex;
	 align-items: center;
}
 .picker-input__text {
	 padding: 10px;
	 border: 1px solid #e4e4e4;
	 outline: none;
	 font-size: 16.8px;
}
 .picker-input__text:disabled {
	 background: #e4e4e4;
}
 .picker-input__clear {
	 position: absolute;
	 top: 50%;
	 -webkit-transform: translateY(-50%);
	         transform: translateY(-50%);
	 right: 10px;
	 cursor: pointer;
}
 .picker {
	 display: inline-block;
}
 .picker__container {
	 position: absolute;
	 z-index: 100;
}
 .picker__container.portal {
	 position: fixed;
	 top: 50%;
	 left: 50%;
	 -webkit-transform: translateX(-50%) translateY(-50%);
	         transform: translateX(-50%) translateY(-50%);
}
 .picker__container__include-time {
	 border: 1px solid #e4e4e4;
}
 .picker__container__include-time .calendar__item, .picker__container__include-time .time__container {
	 border: none;
}
 .picker__container__tab {
	 margin: 10px 0;
}
 .picker__container__tab button {
	 padding: 5px 10px;
	 outline: none;
	 display: inline-flex;
	 align-items: center;
	 background: none;
	 border: none;
	 border-bottom: 2px solid #e4e4e4;
}
 .picker__container__tab button.active {
	 color: #49599a;
	 border-bottom: 2px solid #49599a;
}
 .picker__container__tab button:first-child {
	 border-right: none;
}
 .picker__container__tab button svg {
	 margin-right: 5px;
}
 .time__container {
	 display: inline-flex;
	 align-items: center;
	 border: 1px solid #e4e4e4;
	 padding: 15px;
	 background: white;
	 font-family: 'Lato';
	 font-size: 12px;
}
 .time__container__div {
	 margin: 0 10px;
}
 .time__container__type {
	 display: flex;
	 flex-direction: column;
	 margin-left: 10px;
}
 .time-input {
	 display: inline-block;
	 width: 40px;
	 overflow: hidden;
}
 .time-input__up, .time-input__down {
	 border: 1px solid #e4e4e4;
}
 .time-input__up button, .time-input__down button {
	 outline: none;
	 width: 100%;
	 cursor: pointer;
	 border: none;
}
 .time-input__text {
	 width: 100%;
	 border-left: 1px solid #e4e4e4;
	 border-right: 1px solid #e4e4e4;
	 box-sizing: border-box;
}
 .time-input__text input {
	 width: 100%;
	 box-sizing: border-box;
	 border: none;
	 font-size: 15px;
	 padding: 5px;
	 text-align: center;
	 outline: none;
}
 .calendar {
	 display: inline-block;
	 background: white;
	 font-size: 12px;
}
 .calendar *, .calendar *:before, .calendar *:after {
	 box-sizing: border-box;
}
 .calendar__container {
	 width: 270px;
	 font-family: 'Roboto', sans-serif;
	 display: none;
}
 .calendar__list {
	 display: table;
}
 .calendar__item {
	 display: table-cell;
	 border: 1px solid #ececec;
}
 .calendar__item:not(:first-child) {
	 border-left: none !important;
}
 .calendar--show {
	 display: inline-block;
}
 .calendar__head {
	 position: relative;
	 background: #7986cb;
	 padding: 10px 6px;
}
 .calendar__head--title {
	 font-size: 1.3em;
	 color: white;
	 text-align: center;
	 margin: 4px;
}
 .calendar__head--button {
	 outline: none;
	 border: none;
	 cursor: pointer;
	 background: none;
	 font-size: 20px;
}
 .calendar__head--button svg {
	 fill: white;
}
 .calendar__head--prev, .calendar__head--next {
	 position: absolute;
	 top: 0;
	 bottom: 0;
	 display: flex;
	 align-items: center;
}
 .calendar__head--prev {
	 left: 0;
}
 .calendar__head--next {
	 right: 0;
}
 .calendar__panel--show {
	 display: block !important;
}
 .calendar__panel--today {
	 background: #aab6fe;
	 padding: 5px;
	 display: none;
}
 .calendar__panel--today h2 {
	 margin: 0;
	 cursor: pointer;
	 font-size: 12px;
	 text-align: center;
}
 .calendar__body--table {
	 width: 100%;
	 table-layout: fixed;
	 text-align: center;
	 border-spacing: none;
	 border-collapse: collapse;
}
 .calendar__body--table th {
	 height: 30px;
	 vertical-align: middle;
	 color: #212121;
}
 .calendar__day {
	 vertical-align: top;
	 padding-top: 5px;
	 height: 40px;
	 cursor: pointer;
}
 .calendar__day self:hover:not(&--disabled) {
	 background: #aab6fe;
	 cursor: pointer;
}
 .calendar__day--0 {
	 color: red;
}
 .calendar__day--6 {
	 color: blue;
}
 .calendar__day--today {
	 background: #fff9c4;
}
 .calendar__day--disabled {
	 color: #ddd;
	 cursor: auto;
	 cursor: initial;
}
 .calendar__day--start, .calendar__day--end, .calendar__day--selected {
	 background: #7986cb;
	 color: #fff;
}
 .calendar__day--start:hover, .calendar__day--end:hover, .calendar__day--selected:hover {
	 background: #7986cb;
}
 .calendar__day--range {
	 background: #dce1ff;
}
 .calendar__day--text {
	 display: block;
	 font-size: 10px;
}
 .calendar__year, .calendar__month {
	 height: 55px;
	 vertical-align: middle;
}
 .calendar__year:hover, .calendar__month:hover {
	 background: #aab6fe;
	 cursor: pointer;
}
 
.page-link:hover {
    color:white;
    background-color:#19315A;
    transition:200ms;
}

.page-item.active .page-link {
    background-color:#19315A;
}

@media only screen and (max-width: 600px) {
    .page-item:first-child{
        padding-top:0px;
    }
    
    .page-item:last-child{
        padding-top:0px;
    }
    .page-item {
        padding-top:8px;
    }

    .dataTables_info {
        text-align:center;
        margin-bottom:10px;
    }

}

.import-modal label {
  margin-bottom: 0.3rem;
  font-weight: 600;
}
.import-modal .select-file-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #cccccc;
  border-radius: 7px;
  padding: 1rem;
  margin-bottom: 0.5rem;
}
.import-modal .select-file-wrapper p {
  margin: 0;
}
.import-modal .select-file-wrapper .btn {
  cursor: pointer;
}
.import-modal .download-wrapper {
  display: inline-block;
  padding: 0.5rem;
  padding-bottom: 0;
  color: #19315a;
  cursor: pointer;
}
.import-modal .download-wrapper:hover {
  color: #3a5686;
}
.import-modal label {
  margin-bottom: 0.3rem;
  font-weight: 600;
}
.import-modal .select-file-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #cccccc;
  border-radius: 7px;
  padding: 1rem;
  margin-bottom: 0.5rem;
}
.import-modal .select-file-wrapper p {
  margin: 0;
}
.import-modal .select-file-wrapper .btn {
  cursor: pointer;
}
.import-modal .download-wrapper {
  display: inline-block;
  padding: 0.5rem;
  padding-bottom: 0;
  color: #19315a;
  cursor: pointer;
}
.import-modal .download-wrapper:hover {
  color: #3a5686;
}
.btn-component button {
    background-color:#19315A;
    transition:200ms;
    border:0px;
  
} 



.btn-component button:hover {
    background-color:#2B549F;
}

.btn-primary {
    background-color:#19315A !important;
    border:0px;
}
.btn-primary:hover {
    background-color:#2B549F !important;
}

.btn-success {
    background-color:#118844 !important;
    border:0px;
}
     
.btn-warning {
    background-color:#F9BA72 !important;
    border:0px;
}

.btn-danger {
    background-color:#CC4422 !important;
    border:0px;
}
.custom-btn {
    width:100px;
    font-size:12px; 
}
.details-header a {
  color: #72747b;
  text-decoration: underline;
}
.details-header a:hover {
  text-decoration: none;
}

.mlinkImage {
  max-height: 12px;
}

.mLinkA {
  display: flex;
  align-items: center;
  justify-content: center;
}

.noImage, .previewImage {
  max-width: 100%;
  width: 220px;
  border: 1px solid #b2b2b2;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-left: auto;
}
.noImage p, .previewImage p {
  max-width: 130px;
}

.previewImage {
  width: 100%;
}
.previewImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.previewImage .expand-image {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.previewImage .expand-image img {
  width: 50px;
  object-fit: contain;
}
.previewImage:hover .expand-image {
  display: block;
}

.product_details_header {
  justify-content: space-between;
  display: flex;
  align-items: flex-start;
}
.product_details_header .col-md-3 {
  min-height: 26px;
}

.header_cid_dropdown {
  max-width: 100%;
  width: 210px;
  border: 1px solid rgba(152, 166, 173, 0.2);
  border-radius: 4px;
  height: 26px;
  overflow: hidden;
  position: relative;
}
.header_cid_dropdown .toggle_dropdown {
  cursor: pointer;
  position: absolute;
  top: 3px;
  right: 0;
  width: 26px;
  height: 20px;
  display: flex;
  align-items: center;
  border-left: 1px solid rgba(152, 166, 173, 0.2);
  justify-content: center;
}
.header_cid_dropdown .toggle_dropdown i {
  font-size: 16px;
  transition: all 0.6s;
}
.header_cid_dropdown.open_dropdown {
  height: auto;
  position: absolute;
  z-index: 9;
  background: #fff;
}
.header_cid_dropdown.open_dropdown .toggle_dropdown > i {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  transition: all 0.6s;
}
.header_cid_dropdown .cid_item {
  width: 100%;
  display: flex;
  align-items: center;
  height: 24px;
  padding: 0 5px;
}
.header_cid_dropdown .cid_item:hover {
  background-color: #ededed;
}
.header_cid_dropdown .cid_item p {
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.header_cid_dropdown .cid_item .mLinkA {
  margin-left: auto;
  margin-right: 25px;
}
.header_cid_dropdown .cid_item img {
  width: 14px;
  object-fit: contain;
  margin-right: 5px;
}

.header__dropdown__select {
  height: 26px !important;
  padding-left: 8px !important;
}
.header__dropdown__select .custom__select__dropdown {
  width: 100% !important;
  left: 0 !important;
  padding: 8px 12px;
}
.header__dropdown__select .custom__select__title {
  font-size: 10px;
}
.react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text {
  display: block;
  width: 23%;
  height: 42px;
  line-height: 42px;
  float: left;
  margin: 1%;
  cursor: pointer;
  border-radius: 4px;
  color: #6c757d;
}

.react-datepicker__month-container {
  width: 248px;
  padding: 10px 0;
}

.react-datepicker__header {
  background-color: transparent;
  border: none;
}

.react-datepicker__navigation {
  top: 22px;
}

.react-datepicker__navigation--previous {
  left: 20px;
}

.react-datepicker__navigation--next {
  right: 20px;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
  background-color: #19315a;
  color: #fff !important;
}
.react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover, .react-datepicker__quarter-text--keyboard-selected:hover, .react-datepicker__year-text--keyboard-selected:hover {
  background-color: #2B549F;
}

.calendarInput {
  position: relative;
}

.calendarIcon {
  position: absolute;
  width: 20px;
  right: 12px;
  top: 8px;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  color: #6c757d;
}
.m_infoButton {
  margin-left: 5px;
  position: relative;
  cursor: pointer;
}
.m_infoButton img {
  width: 16px;
}

.m_infoButton:hover::before {
  content: "Always enter max. values per period";
  position: absolute;
  top: -95px;
  left: -10px;
  width: 240px;
  height: 85px;
  background-color: #566676;
  border-radius: 3px;
  padding: 4px;
  color: #fff;
  font-weight: lighter;
  font-size: 12px;
}

.m_infoButton:hover::after {
  content: "";
  position: absolute;
  top: -10px;
  left: 0;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #566676;
}

.CTinfoButton:hover::before {
  content: "Determined by the contract with the manufacturer if we buy products or receive consignment products. This setting has implications on the margin calculation";
}

.CPinfoButton:hover::before {
  content: "Share of the net sales which eBazaaris receives as commission for every successfully sold product in case of consignment products";
}

.statusSelect #status {
  position: unset;
  left: unset;
  margin: unset;
  top: unset;
}
.product-tabs {
  display: flex;
  align-items: flex-end;
}
.product-tabs .tab {
  padding: 0.5rem 1.5rem;
  font-weight: 600;
  border-bottom: 2px solid rgba(152, 166, 173, 0.2);
  cursor: pointer;
  flex: none;
}
.product-tabs .tab.active {
  border-bottom-color: #19315a;
}
.product-tabs .tab-bar {
  width: 100%;
  height: 100%;
  border-bottom: 2px solid rgba(152, 166, 173, 0.2);
}

.powerBiEmbed {
  width: 100%;
}
.powerBiEmbed iframe {
  width: 100%;
  height: 100%;
  border: 0 !important;
}

.hidden {
  display: none;
}

.custom-flex {
  display: flex;
  justify-content: space-between;
}
.custom-flex div:first-child {
  width: 55%;
}
.custom-flex div:last-child {
  width: 40%;
}
.custom-flex div:last-child input {
  text-align: center;
}

#unicEconomics td, th {
  text-align: left !important;
}

#unicEconomics .table thead th {
  vertical-align: unset;
  border-bottom: 2px solid #dee2e6;
  border-top: 1px solid #dee2e6;
  padding: 1rem 0.85rem;
  white-space: unset;
  width: 160px;
}

.symbol {
  position: absolute;
  top: 8px;
  right: 20px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance: textfield;
  /* Firefox */
}

.tooltipHeading {
  overflow: unset !important;
  display: flex;
  margin-bottom: 0.6rem !important;
}

.log_divider {
  width: 100%;
  margin: 10px 0 20px;
  height: 2px;
  background: #ced4da;
}

.priceList td {
  overflow: unset !important;
}

.unitEconomics td span {
  display: flex;
  align-items: center;
}

.unitEconomics td .u_infoButton {
  position: relative;
  top: -1px;
}

.priceList td .u_infoButton {
  position: absolute;
  right: -20px;
  top: 7px;
}

.reverse_info > .infoBox {
  top: 32px;
  bottom: unset !important;
}

.reverse_info:hover::after {
  top: 25px !important;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.red-info-icon {
  color: #CC4422;
}

.green-icon {
  color: #118844;
}

.gray-icon {
  color: #999999;
}

.yellow-info-icon {
  color: #F9BA72;
}

.error-message {
  font-weight: 500;
  font-family: "DIN2014";
  color: #525254;
  font-size: 0.9rem;
}

.tooltip-link {
  text-decoration: underline;
}

.input-group-text {
  width: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.input-group-text {
  background-color: #f2f2f2;
}

.product-tabs-custom {
  -webkit-transform: translateY(-37px);
          transform: translateY(-37px);
}

.ov-hid {
  overflow-y: hidden !important;
}

.sticky_buttons_wrapper {
  position: fixed;
  z-index: 21474830000;
  background: #fff;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 12px 10px;
  display: flex;
  justify-content: flex-end;
  box-shadow: 1px 0px 5px 3px rgba(0, 0, 0, 0.15);
}

.singleProductWrapper {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 370px);
  -webkit-transform: translateY(-20px);
          transform: translateY(-20px);
}

.singleProductWrapper::-webkit-scrollbar {
  width: 2px;
}

.singleProductWrapper::-webkit-scrollbar-thumb {
  background-color: #003566;
  outline: 1px solid #003566;
}

.custom_error_container {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  width: -webkit-max-content;
  width: max-content;
  padding: 8px 10px;
  box-shadow: 0 4px 4px 0px rgba(0, 0, 0, 0.15);
  position: absolute;
  bottom: -8px;
  background: #fff;
  left: 50px;
  z-index: 9999;
}
.custom_error_container img {
  width: 18px;
  margin-right: 5px;
}
.custom_error_container p {
  margin-bottom: 0;
}

.invalid {
  background-color: rgba(241, 85, 108, 0.18);
  border-color: rgba(241, 85, 108, 0.18) !important;
}
.invalid > div:first-child {
  background-color: transparent !important;
}

td.rel_vis {
  position: relative;
  overflow: visible;
}

.custom__select {
  width: 100%;
  height: 36px;
  border: 1px solid #cfcfcf;
  display: flex;
  cursor: pointer;
  align-items: center;
  padding-left: 16px;
  position: relative;
}
.custom__select .custom__select_inner {
  width: calc(100% - 36px);
  margin-bottom: 0;
}
.custom__select .custom__select_icon {
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}
.custom__select .custom__select_icon::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 20px;
  background-color: #cfcfcf;
}

.custom__select__tags {
  width: 100%;
  display: flex;
}

.custom__select__tag {
  margin-right: 5px;
  background: #dddddd;
  padding: 2px 6px;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.custom__select__tag span:first-child {
  width: 40px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.custom__select__tag .custom__select__tag_close {
  margin-left: 10px;
  font-size: 10px;
}

.custom__select__placeholder {
  margin-bottom: 0;
}

.custom__select__dropdown {
  position: absolute;
  bottom: -320px;
  height: 300px;
  background: #fff;
  z-index: 999;
  width: 96%;
  border: 1px solid #cfcfcf;
  left: 2%;
  padding: 8px 20px;
}
.custom__select__dropdown .custom__select__dropdown__header {
  display: flex;
  align-items: center;
}
.custom__select__dropdown .custom__select__dropdown__header .custom__select__dropdown__header__tr__checkbox, .custom__select__dropdown .custom__select__dropdown__header .custom__select__dropdown__header__tr__close {
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom__select__dropdown .custom__select__dropdown__header .custom__select__dropdown__header__tr__search {
  padding: 0 10px;
  width: calc(100% - 130px);
  position: relative;
}
.custom__select__dropdown .custom__select__dropdown__header .custom__select__dropdown__header__tr__search input {
  border: 1px solid #cfcfcf;
  outline: none;
  height: 30px;
  padding: 0 10px;
  border-radius: 4px;
}
.custom__select__dropdown .custom__select__dropdown__header .custom__select__dropdown__header__tr__search i {
  font-size: 22px;
  position: absolute;
  color: #cfcfcf;
  right: 18px;
  top: 0;
}

.custom__select__create__button {
  margin-right: 10px;
}

.custom__select__dropdown__body {
  margin-top: 10px;
  height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}
.custom__select__dropdown__body .custom__select__dropdown__body__item {
  display: flex;
  width: 100%;
  margin-bottom: 4px;
  padding: 4px;
  align-items: center;
}
.custom__select__dropdown__body .custom__select__dropdown__body__item.checked {
  background-color: #cde7fe;
}
.custom__select__dropdown__body .custom__select__dropdown__body__item:hover {
  background-color: #dddddd;
}
.custom__select__dropdown__body .custom__select__dropdown__body__item .custom__select__dropdown__body__item__checkbox {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.custom__select__dropdown__footer {
  display: flex;
  align-items: center;
  height: 40px;
}
.custom__select__dropdown__footer .custom__select__dropdown__footer__divider {
  width: 200px;
  height: 1px;
  background: #cfcfcf;
  margin: 0 auto;
}

.custom__tags__wrapper {
  display: flex;
  width: 100%;
  border: 1px solid #cfcfcf;
  padding: 8px 8px 30px;
}
.product-form {
  background: #ffffff;
  border-radius: 5px;
  margin-bottom: 1rem;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
}
.product-form .product-form__header {
  padding: 1.2rem;
  cursor: pointer;
}
.product-form .product-form__header h4 {
  font-size: 0.9rem;
}
.product-form .product-form__header .mdi-chevron-down {
  font-size: 1.5rem;
  line-height: 0.5;
}
.product-form hr {
  height: 1px;
  background: #ebebeb;
  margin: 0 1.2rem;
}
.product-form .product-form__body {
  padding: 1.2rem;
}
.product-form .product-form__body label {
  font-weight: 600;
  margin-bottom: 0.3rem;
}

.marketplaceLinkWrapper {
  position: relative;
}
.marketplaceLinkWrapper ._marketplaceLink {
  position: absolute;
  top: 12px;
  border-left: 2px solid #ccc;
  right: 14px;
  height: 20px;
  display: flex;
  align-items: center;
  width: 35px;
  justify-content: center;
}
.marketplaceLinkWrapper ._marketplaceLink img {
  max-height: 15px;
  margin-top: -3px;
}
.product-form {
  background: #ffffff;
  border-radius: 5px;
  margin-bottom: 1rem;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
}
.product-form .product-form__header {
  padding: 1.2rem;
  cursor: pointer;
}
.product-form .product-form__header h4 {
  font-size: 0.9rem;
}
.product-form .product-form__header .mdi-chevron-down {
  font-size: 1.5rem;
  line-height: 0.5;
}
.product-form hr {
  height: 1px;
  background: #ebebeb;
  margin: 0 1.2rem;
}
.product-form .product-form__body {
  padding: 1.2rem;
}
.product-form .product-form__body label {
  font-weight: 600;
  margin-bottom: 0.3rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}

.sm-f-icon {
  height: 50% !important;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.product-tabs {
  display: flex;
  align-items: flex-end;
  margin-bottom: 0.5rem;
}
.product-tabs .tab {
  padding: 0.5rem 1.5rem;
  font-weight: 600;
  border-bottom: 2px solid rgba(152, 166, 173, 0.2);
  cursor: pointer;
  flex: none;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.product-tabs .tab.active {
  border-bottom-color: #19315a;
}
.product-tabs .tab-bar {
  width: 100%;
  height: 100%;
  border-bottom: 2px solid rgba(152, 166, 173, 0.2);
}

.powerBiEmbed {
  width: 100%;
  height: 100%;
}
.powerBiEmbed iframe {
  width: 100%;
  height: 100%;
  border: 0 !important;
}

.hidden {
  display: none;
}

#listing-nav.nav-pills {
  background-color: #fff;
  height: 100%;
}
#listing-nav.nav-pills .nav-link {
  margin-bottom: 0;
  border-bottom: 1px solid #dedede;
  border-radius: 0;
  cursor: pointer;
}
#listing-nav.nav-pills .nav-link.active {
  background-color: #fff;
  border-left: 3px solid #19315a;
  color: grey;
}

#listing-description .ql-toolbar.ql-snow {
  border: 2px solid #ced4da;
  border-radius: 0.2rem;
  padding: 15px;
  margin-bottom: 20px;
}
#listing-description .ql-container.ql-snow {
  border: none;
}
#listing-description .ql-editor {
  border: 2px solid #ced4da;
  padding: 15px;
  border-radius: 0.2rem;
  min-height: 200px;
}

.text-green {
  color: #118844;
}

.overflow-auto {
  overflow: auto;
  padding-bottom: 25px;
}

.listing-image {
  flex: 0 0 250px;
  margin-right: 20px;
  height: 230px;
}
.listing-image .image-prev {
  height: 150px;
  border: 1px solid #dfdfdf;
  border-radius: 10px;
  margin-bottom: 15px;
  max-width: 250px;
  width: 250px;
  position: relative;
}
.listing-image .image-prev:hover:not(.upload-image) {
  background: #a8a8a8;
}
.listing-image .image-prev:hover > img {
  opacity: 0.5;
}
.listing-image .image-prev > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.listing-image .image-prev > input {
  position: absolute;
  top: 6px;
  left: 30px;
  width: 18px;
  height: 18px;
}
.listing-image .image-prev .expand-image {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  cursor: pointer;
}
.listing-image .image-prev .expand-image img {
  width: 50px;
}
.listing-image .image-prev .drag-image {
  position: absolute;
  top: 8px;
  right: 15px;
  cursor: grab;
}
.listing-image .image-prev .drag-image img {
  width: 12px;
}
.listing-image .upload-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed #dfdfdf;
}
.listing-image .upload-image > img {
  width: 24px;
  height: 24px;
  margin-bottom: 25px;
}
.listing-image .img-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.listing-image .img-btn > button {
  background: #dfdfdf;
  border-radius: 15px;
  color: #000;
  font-size: 11px;
  width: 100px;
  height: 24px;
  border: none;
}
.listing-image .img-btn > button:hover, .listing-image .img-btn > button:focus {
  background-color: #dfdfdf;
  outline: none;
  box-shadow: none;
}
.listing-image .img-btn > span {
  font-size: 12px;
  color: #72747b;
}

.customRefresh .spinner {
  top: unset;
}

#custom_textarea {
  resize: none;
  width: 100%;
  height: 450px;
  border: 2px solid #ced4da;
  border-radius: 0.2rem;
  padding: 15px;
  margin-bottom: 20px;
}

.nestable-icon {
  -webkit-transform: translateY(4px) !important;
          transform: translateY(4px) !important;
}

.content_section label {
  overflow: unset !important;
  display: flex;
}

.modal-scroll {
  overflow-y: scroll;
  max-height: calc(100vh - 200px);
}
.import-job-table {
  overflow-x: auto;
}
.import-job-table .import-job-table__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.import-job-table .import-job-table__header .import-job-table__header--item {
  display: flex;
  align-items: center;
}
.import-job-table .import-job-table__header .import-job-table__header--item p {
  margin: 0 0.7rem 0 0;
}
.import-job-table table tr {
  border-bottom: 1px solid #e9e9e9;
}
.import-job-table table tr th {
  text-transform: capitalize;
}
.import-job-table table tr td a {
  color: #72747b;
  text-decoration: underline;
}
.import-job-table table tr td a:hover {
  text-decoration: none;
}
.import-job-table table tr.gray {
  background: #f7f7f7;
}
.import-job-table table tr.white {
  background: #ffffff;
}
.import-job-table table tr .extended-values {
  padding: 1rem 2rem;
}

.modal-error-field__close-btn {
  margin-left: auto;
  height: 16px;
}
.import-loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
}
.import-loading-wrapper .spinner {
  width: 60px;
  height: 60px;
}
.import-loading-wrapper p {
  margin-top: 2rem;
  font-size: 0.9rem;
}
.scroll-x {
  overflow-x: scroll;
  flex-wrap: nowrap;
  padding-bottom: 10px;
  position: relative;
  /* width */
  /* Track */
  /* Handle */
}
.scroll-x::-webkit-scrollbar {
  height: 4px;
}
.scroll-x::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.scroll-x::-webkit-scrollbar-thumb {
  background: transparent;
}
.scroll-x:hover::-webkit-scrollbar-thumb {
  background-color: #888;
}
.scroll-x > li {
  flex: 0 0 fit-content;
  margin-bottom: 0 !important;
}

.scroll-x:hover + .relative {
  display: none;
}

.relative {
  position: relative;
  width: 100%;
}

.slide_indicator {
  position: absolute;
  right: -25px;
  bottom: -28px;
  -webkit-animation: float 2s ease-in-out infinite;
          animation: float 2s ease-in-out infinite;
}
.slide_indicator img {
  max-width: 20px;
}

@-webkit-keyframes float {
  0% {
    -webkit-transform: translatex(0px);
            transform: translatex(0px);
  }
  50% {
    -webkit-transform: translatex(-10px);
            transform: translatex(-10px);
  }
  100% {
    -webkit-transform: translatex(0px);
            transform: translatex(0px);
  }
}

@keyframes float {
  0% {
    -webkit-transform: translatex(0px);
            transform: translatex(0px);
  }
  50% {
    -webkit-transform: translatex(-10px);
            transform: translatex(-10px);
  }
  100% {
    -webkit-transform: translatex(0px);
            transform: translatex(0px);
  }
}
.actions-cell {
  position: relative;
  overflow: visible;
}
.actions-cell i {
  cursor: pointer;
}
.actions-cell .actions-dropdown {
  position: absolute;
  top: 15px;
  right: 40px;
  background: #ffffff;
  text-align: left;
  padding: 0.2rem;
  z-index: 999;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.4);
  border-radius: 3px;
}
.actions-cell .actions-dropdown li {
  list-style-type: none;
  padding: 0.1rem 0.3rem;
  cursor: pointer;
}
.actions-cell .actions-dropdown li:hover {
  background: #f7f7f7;
}
.user-tabs {
  display: flex;
  align-items: flex-end;
  margin-bottom: 1.5rem;
}
.user-tabs .tab {
  padding: 0.5rem 1.5rem;
  font-weight: 600;
  border-bottom: 2px solid rgba(152, 166, 173, 0.2);
  cursor: pointer;
}
.user-tabs .tab.active {
  border-bottom-color: #19315a;
}
.user-tabs .tab-bar {
  width: 100%;
  height: 100%;
  border-bottom: 2px solid rgba(152, 166, 173, 0.2);
}

.pl-50 {
  padding-left: 50px !important;
}
.analytics-tabs .tab:first-child {
  min-width: 152px;
}

.reports-page-table tbody tr td:first-child,
.reports-page-table thead tr th:first-child {
  display: none;
}

.sections-page-table thead tr th:nth-child(5) {
  padding-right: 25px;
}

.list-toggle-btn {
  width: 120px;
  margin-left: 20px;
}

#manufcaturersSelect svg {
  height: 16px;
  width: 16px;
}

.reportPage {
  position: relative;
  margin: 0;
}

.reportPage > div {
  padding: 0;
}

.reportPage:before {
  content: "";
  position: absolute;
  top: 35px;
  width: 100%;
  left: 0;
  height: 2px;
  background-color: rgba(152, 166, 173, 0.6);
}

.rfrBtn > img {
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
  height: 16px;
  width: 16px;
  margin-left: 10px;
}

.custom-table {
  background: #fff !important;
}

.custom-table table {
  width: 100%;
  overflow-x: auto;
  display: block;
  background: #fff !important;
}

.custom-table tbody tr td, .custom-table thead tr th {
  text-align: center !important;
  background: #fff !important;
}

.custom-table thead tr th {
  min-width: 250px;
  width: 350px;
}

.draggableSpan {
  list-style: none;
  padding: 6px 5px;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: 2px solid rgba(66, 114, 138, 0.2);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #ccc;
  margin-bottom: 2px;
}

.custom-table .draggableSpan {
  background-color: transparent !important;
}

.draggableSpan:hover {
  background-color: #ccc;
}

.draggableSpan:focus, .draggableSpan:active {
  background-color: #ccc;
}

.draggableSpan > img {
  width: 12px;
  object-fit: contain;
  margin-right: 15px;
  left: 5px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  position: absolute;
}

.flex-direction-column {
  flex-direction: column;
}

.pbi-svg-item {
  display: none !important;
}

.report {
  height: 100%;
}
.report iframe {
  border: none !important;
}
.fallback {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
}
.fallback .fallback__wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
  width: 350px;
  height: 350px;
}
.fallback .fallback__wrapper .fallback__logo-wrapper {
  text-align: center;
}
.fallback .fallback__wrapper .fallback__logo-wrapper .fallback__logo {
  height: 120px;
  object-fit: cover;
}
.fallback .fallback__wrapper p {
  margin: 0;
  text-align: center;
}
.fallback .fallback__wrapper .fallback__primary-text {
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: #000000;
}
.fallback .fallback__wrapper .fallback__secondary-text {
  margin: 2rem 0;
}
#content-settings input[type=number] {
  width: 55px;
  padding: 0;
  margin-bottom: 5px;
  text-align: center;
}

#content-settings input::-webkit-outer-spin-button,
#content-settings input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

#content-settings .table-responsive {
  overflow-y: hidden;
}

#content-settings ul {
  padding-left: 5px;
}

#content-settings ul > li:not(:last-child) {
  margin-bottom: 20px;
}

#content-settings thead tr th:first-child {
  min-width: 140px;
}

#content-settings thead tr th:nth-child(2) {
  min-width: 350px;
}

#content-settings thead tr th:nth-child(3) {
  min-width: 80px;
  text-align: center;
}

#content-settings thead tr th:nth-child(4) {
  width: 100%;
  padding-left: 25px;
}
#backupList thead th, #backupList tbody tr td {
  text-align: center;
  display: block;
  min-width: 160px;
  width: unset;
  border: unset;
}

#backupList thead {
  border-top: 1px solid #dee2e6;
  border-bottom: 2px solid #dee2e6;
}

#backupList thead th:first-child, #backupList tbody tr td:first-child {
  display: none;
}

#backupList tr {
  display: flex;
}

#backupList thead th:last-child, #backupList tbody tr td:last-child {
  text-align: left;
  min-width: 550px !important;
}

#backupList tbody tr td:nth-child(4), #backupList thead th:nth-child(4),
#backupList tbody tr td:nth-child(5), #backupList thead th:nth-child(5) {
  min-width: 130px !important;
}

.th-sm {
  width: 60px !important;
}
.fulfilment-costs th, .fulfilment-costs td, .warehouse-costs th, .warehouse-costs td {
  text-align: left !important;
}

.modal {
  overflow-y: auto;
}

.infoButton {
  margin-left: 5px;
  position: relative;
  cursor: pointer;
}
.infoButton img {
  width: 16px;
}

.infoButton:hover::before {
  content: "Always enter max. values per period";
  position: absolute;
  top: -70px;
  left: -10px;
  width: 200px;
  height: 60px;
  background-color: #566676;
  border-radius: 3px;
  padding: 4px;
  color: #fff;
  font-weight: lighter;
  font-size: 12px;
}

.infoButton:hover::after {
  content: "";
  position: absolute;
  top: -10px;
  left: 0;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #566676;
}

.dividerDiv {
  display: flex;
}
.dividerDiv p {
  font-weight: bolder;
  color: #000;
  margin-bottom: 0;
}

.currencyIcon {
  position: absolute;
  top: 9px;
  right: 10px;
}

td {
  max-width: 220px;
}
.unitEconomics td {
  overflow: unset;
}

.u_infoButton {
  margin-left: 5px;
  position: relative;
  cursor: pointer;
}
.u_infoButton img {
  width: 16px;
}

.vm {
  background-color: #e7e7e7 !important;
}

.vm td {
  vertical-align: middle;
}

.u_infoButton > .infoBox {
  position: absolute;
  bottom: 29px;
  left: -20px;
  width: 180px;
  height: auto;
  background-color: #566676;
  border-radius: 3px;
  padding: 10px;
  color: #fff;
  font-weight: lighter;
  font-size: 12px;
  display: none;
  z-index: 99;
}

.u_infoButton:hover .infoBox {
  display: block;
}

.infoBox > span {
  white-space: break-spaces;
  display: block;
}

.u_infoButton:hover::after {
  content: "";
  position: absolute;
  top: -10px;
  left: 0;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #566676;
}

.upsd_infoButton:hover::after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  top: 26px;
}

.upsd_infoButton > .infoBox {
  bottom: -160px;
}

.tooltipDown .u_infoButton > .infoBox {
  bottom: -90px;
}

.tooltipDown .u_infoButton:hover::after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  top: 28px;
}

.unit_ec_error .fallback {
  align-items: self-start;
  margin-top: 100px;
}

.unit_ec_error .fallback .fallback__wrapper {
  width: 500px;
  min-height: 350px;
  height: auto;
}

.unit_ec_error .text-error-normal {
  font-size: 16px;
}
