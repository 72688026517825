.import-modal {
  label {
    margin-bottom: 0.3rem;
    font-weight: 600;
  }

  .select-file-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 7px;
    padding: 1rem;
    margin-bottom: 0.5rem;

    p {
      margin: 0;
    }

    .btn {
      cursor: pointer;
    }
  }

  .download-wrapper {
    display: inline-block;
    padding: 0.5rem;
    padding-bottom: 0;
    color: #19315a;
    cursor: pointer;

    &:hover {
      color: #3a5686;
    }
  }
}
