.m_infoButton{
    margin-left: 5px;
    position: relative;
    cursor: pointer;

    img{
        width: 16px;
    }
}


.m_infoButton:hover::before{
    content: 'Always enter max. values per period';
    position: absolute;
    top: -95px;
    left: -10px;
    width: 240px;
    height: 85px;
    background-color: #566676;
    border-radius: 3px;
    padding: 4px;
    color: #fff;
    font-weight: lighter;
    font-size: 12px;
}
.m_infoButton:hover::after{
    content: '';
    position: absolute;
    top: -10px;
    left: 0;
    width: 0; 
    height: 0; 
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #566676;
}

.CTinfoButton:hover::before{
    content: 'Determined by the contract with the manufacturer if we buy products or receive consignment products. This setting has implications on the margin calculation';
}


.CPinfoButton:hover::before{
    content: 'Share of the net sales which eBazaaris receives as commission for every successfully sold product in case of consignment products';
}

.statusSelect #status{
    position: unset;
    left: unset;
    margin: unset;
    top: unset;
}