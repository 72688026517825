td {
  max-width: 175px;
  position: relative;
}

td > span, td > p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 18px;
}

.disabled-link {
  pointer-events: none;
}

.td-link {
  color: #00488c;
}

li > img.flag{
  width: 20px;
  margin: 0 15px;
}

li._li{
  list-style-type: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

td > a{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}



li::marker{
  display: none;
  content: unset;
}

.action_buttons{
  position: absolute;
  bottom: -30px;
  right: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  z-index: 999;
}

.action_btn{
  width: 30px;
  height: 30px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 4px;
  margin: 0 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.action_btn:hover{
  background-color: #efefef;
}