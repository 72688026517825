.product-form {
  background: #ffffff;
  border-radius: 5px;
  margin-bottom: 1rem;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);

  .product-form__header {
    padding: 1.2rem;
    cursor: pointer;

    h4 {
      font-size: 0.9rem;
    }

    .mdi-chevron-down {
      font-size: 1.5rem;
      line-height: 0.5;
    }
  }

  hr {
    height: 1px;
    background: #ebebeb;
    margin: 0 1.2rem;
  }

  .product-form__body {
    padding: 1.2rem;

    label {
      font-weight: 600;
      margin-bottom: 0.3rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 250px;
    }
  }
}

.sm-f-icon{
  height: 50% !important;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}