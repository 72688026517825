.product-tabs {
  display: flex;
  align-items: flex-end;
  margin-bottom: 0.5rem;

  .tab {
    padding: 0.5rem 1.5rem;
    font-weight: 600;
    border-bottom: 2px solid rgba(152, 166, 173, 0.2);
    cursor: pointer;
    flex: none;
    user-select: none;

    &.active {
      border-bottom-color: #19315a;
    }
  }

  .tab-bar {
    width: 100%;
    height: 100%;
    border-bottom: 2px solid rgba(152, 166, 173, 0.2);
  }
}

.powerBiEmbed {
  width: 100%;
  height: 100%;  
  iframe {
    width: 100%;
    height: 100%;
    border: 0 !important;
  }
}

.hidden {
  display: none;
}

#listing-nav.nav-pills{
  background-color: #fff;
  height: 100%;

  .nav-link{
    margin-bottom: 0;
    border-bottom: 1px solid rgb(222, 222, 222);
    border-radius: 0;
    cursor: pointer;

    &.active{
      background-color: #fff;
      border-left: 3px solid #19315a;
      color: grey;
    }
  }
}

#listing-description{
  .ql-toolbar.ql-snow{
    border: 2px solid #ced4da;
    border-radius: 0.2rem;
    padding: 15px;
    margin-bottom: 20px;
  }
  .ql-container.ql-snow{
    border: none;
  }
  .ql-editor{
    border: 2px solid #ced4da;
    padding: 15px;
    border-radius: 0.2rem;
    min-height: 200px;
  }
}

.text-green{
  color: #118844;
}

.overflow-auto{
  overflow: auto;
  padding-bottom: 25px;
}

.listing-image {
  flex: 0 0 250px;
  margin-right: 20px;
  height: 230px;

  .image-prev{
    height: 150px;
    border: 1px solid #dfdfdf;
    border-radius: 10px;
    margin-bottom: 15px;
    max-width: 250px;
    width: 250px;
    position: relative;

    &:hover:not(.upload-image){
      background: #a8a8a8;
    }

    &:hover > img{
      opacity: .5;
    }

    & > img{
      object-fit: contain;
      width: 100%;
      height: 100%;
    }

    & > input{
      position: absolute;
      top: 6px;
      left: 30px;
      width: 18px;
      height: 18px;
    }

    .expand-image{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;

      img{
        width: 50px;
      }
    }

    .drag-image{
      position: absolute;
      top: 8px;
      right: 15px;
      cursor: grab;

      img{
        width: 12px;
      }
    }
  }

  .upload-image{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px dashed #dfdfdf;

    & > img{
      width: 24px;
      height: 24px;
      margin-bottom: 25px;
    }
  }

  .img-btn{
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > button{
      background: #dfdfdf;
      border-radius: 15px;
      color: #000;
      font-size: 11px;
      width: 100px;
      height: 24px;
      border: none;

      &:hover, &:focus{
        background-color: #dfdfdf;
        outline: none;
        box-shadow: none;
      }
    }

    & > span{
      font-size: 12px;
      color: #72747b;
    }
  }
}

.customRefresh .spinner{
  top: unset;
}

#custom_textarea{
  resize: none;
  width: 100%;
  height: 450px;
  border: 2px solid #ced4da;
    border-radius: 0.2rem;
    padding: 15px;
    margin-bottom: 20px;
}


.nestable-icon{
  transform: translateY(4px) !important;
}

.content_section label{
  overflow: unset !important;
  display: flex;
}

.modal-scroll{
  overflow-y: scroll;
  max-height: calc(100vh - 200px);
}